/** @jsx jsx */
import React, { useState } from "react";
import { Col, Container, Row } from "react-bootstrap";
import { jsx } from "@emotion/react";
import { useMediaQuery } from 'react-responsive';
import { Link } from "gatsby";
import {
  EscolaBiligue,
  content,
} from "../assets/styles/AboutTwo.styles";

import ImgEscolaDePresente from "../assets/images/LP-kingdom/ensino-bilingue.png";

const AboutTwo = () => {
  const [setCounter] = useState({
    startCounter: false,
  });
  const isMobile = useMediaQuery({
    query: '(max-width: 850px)'
  })

  return (
    <section css={EscolaBiligue}>
      {isMobile && 
      <Col
      className="img-container px-0"
      data-aos="fade-right"
      lg={6}
      md={12}
      sm={12}
    >
      <img src={ImgEscolaDePresente} alt=" " />
    </Col>}
      <Container fluid>
        <Row className="title-content">
        {!isMobile &&
          <Col
          className="img-container px-0"
          data-aos="fade-right"
          lg={6}
          md={12}
          sm={12}
        >
          <img src={ImgEscolaDePresente} alt=" " />
        </Col>}
          <Col css={content} lg={6} md={12} sm={12}>
            <div>
              <h1 style={{ maxWidth: 450 }}>Um ensino bilíngue com aulas e imersões diárias</h1>
              <p style={{ maxWidth: 450, marginBottom: 40 }}>Diariamente, os alunos têm contato com o inglês em diferentes aulas. No Ensino Fundamental, são desenvolvidas atividades com foco na formação das quatro principais habilidades: fala, compreensão auditiva, escrita e leitura.</p>
              <div className="btn-section">
                <Link to="/fale-conosco" style={{ color: '#FFFFFF' }}>Quero agendar uma visita</Link>
              </div>
            </div>
          </Col>        
        </Row>
      </Container>
    </section>
  );
};

export default AboutTwo;
