/** @jsx jsx */
import React, { useState, useEffect } from "react";
import { Col, Container, Row } from "react-bootstrap";
import { jsx } from "@emotion/react";
import { Link } from "gatsby";
import {
  AutoralAfetuosaBilingue,
  content,
  iconsBg6,
  desktop,
  mobile
} from "../assets/styles/PrimeiroPasso.style";

const PrimeiroPasso = () => {
  const [scrollPosition, setScrollPosition] = useState(0);

  useEffect(() => {
    const handleScroll = () => {
        setScrollPosition(window.scrollY/2);
    };

    window.addEventListener('scroll', handleScroll);

    return () => {
        window.removeEventListener('scroll', handleScroll);
    };
}, []);

  return (
    <section css={AutoralAfetuosaBilingue}>
      <Col css={mobile} className="img-container px-0" lg={5} md={12} sm={12}>
          <div
            css={iconsBg6}
            className="bg-image"
          ></div>
        </Col>
      <Container fluid>
        <Row className="title-content">
          <Col css={content} lg={7} md={12} sm={12}>
            <div className="col-content">
              <h2>Autoral, afetuosa & bilíngue</h2>
              <p>Crescemos com seu filho! A partir de 2025, a Kingdom começou a atuar no segmento de Educação Fundamental Anos finais.</p>
              <p style={{ textTransform: "uppercase", fontWeight: 600 }}>Grade horária focada em excelência acadêmica e habilidades pessoais</p>
              <p>Horário Integral<br/>
                Programa bilíngue<br/>
                Foco em excelência acadêmica e habilidades pessoais 
              </p>
              <div className="button-link">
                <Link to="/a-escola#abordagem" target="_blank">Conheça a metodologia Kingdom</Link>
              </div>
            </div>
          </Col>
          <Col css={desktop} className="img-container px-0" lg={5} md={12} sm={12}>
          <div
            css={iconsBg6}
            className="bg-image"
          ></div>
        </Col>
        </Row>
      </Container>
    </section>
  );
};

export default PrimeiroPasso;
