import React from "react";
import Layout from "@/components/layout";
import Footer from "@/components/footer";
import HeaderTitle from "@/components/HeaderTitleEFII";
import AutoralAfetuosaBilingue from "@/components/AutoralAfetuosaBilingueContent";
import EnsinoBilingue from "@/components/EnsinoBilingueContent";
import EscolaSemCantina from "@/components/EscolaSemCantinaContent";
import SalaDeAulaLab from "@/components/SalaDeAulaLabContent";
import CTAAtividadeFisica from "@/components/CTAAtividadeFisica";
import SearchContextProvider from "@/context/search-context";
import MenuContextProvider from "@/context/menu-context";
import HeaderOne from '@/components/header-one';
import EducacaoFundamentalSliderContainer from '@/components/EducacaoFundamentalSliderContainer';
import SEO from '../components/seo'

const EnsinoFundamentalIIPage = () => {
  return (
    <MenuContextProvider> 
      <SearchContextProvider>
        <Layout>
          <SEO
            title="Ensino Fundamental II bilíngue em Brasília| Kingdom School"
            description="O Programa bilíngue e focado em excelência acadêmica prepara o aluno Kingdom para o Ensino Médio e a vida universitária."
          />
          <HeaderOne />
          <HeaderTitle />
          <AutoralAfetuosaBilingue />
          <EducacaoFundamentalSliderContainer />
          <EnsinoBilingue />
          <SalaDeAulaLab />
          <EscolaSemCantina />
          <CTAAtividadeFisica />
          <Footer /> 
        </Layout>
      </SearchContextProvider>
    </MenuContextProvider>
  );
};

export default EnsinoFundamentalIIPage;
