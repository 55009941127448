/** @jsx jsx */
import React, { useState, useEffect, Fragment } from "react";
import { Col, Container, Row } from "react-bootstrap";
import { jsx } from "@emotion/react";
import BgIcons3 from "../assets/images/LP-kingdom/sala-de-aula.png";
import { salaLAB, ctaBemEstar } from "../assets/styles/UmaEscolaAutoralContent.styles";

import {
  desktop,
  mobile
} from "../assets/styles/PrimeiroPasso.style";

const PrimeiroPasso = () => {
  const [scrollPosition, setScrollPosition] = useState(0);

  useEffect(() => {
    const handleScroll = () => {
      setScrollPosition(window.scrollY / 2);
    };

    window.addEventListener('scroll', handleScroll);

    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  return (
    <Fragment>
      <section css={ctaBemEstar}>
        <Container>
          <Row>
            <Col>
              <h2>Bem-estar acima da média se constrói desde cedo</h2>
            </Col>
          </Row>
        </Container>
      </section>
      <section css={salaLAB} style={{ background: 'white' }}>
        <Container>
          <Row>
            <Col css={mobile} data-aos="fade-left" lg={5} md={5} sm={12}>
              <img className="imagemEscolaComposta" src={BgIcons3} alt="imagem" />
            </Col>
            <Col data-aos="fade-right" lg={6} md={6} sm={12}>
              <div className="content-box">
                <h2>Salas de aula-laboratório.</h2>
                <p>A cada disciplina os alunos se direcionam para a sala da matéria que irão trabalhar de acordo com a grade-horária. Essa prática traz para o aluno uma maior capacidade de adaptação, organização, interesse e facilidade no aprendizado.</p>
              </div>
            </Col>
            <Col className="px-0" lg={1} md={1} sm={12} />
            <Col css={desktop} data-aos="fade-left"lg={5} md={5} sm={12}>
              <img className="imagemEscolaComposta" src={BgIcons3} alt="imagem" />
            </Col>
          </Row>
        </Container>
      </section>
    </Fragment>
  );
};

export default PrimeiroPasso;
