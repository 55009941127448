/** @jsx jsx */
import React, { useState } from "react";
import { Col, Container, Row } from "react-bootstrap";
import { jsx } from "@emotion/react";
import { useMediaQuery } from 'react-responsive';
import { Link } from "gatsby";
import {
  EscolaSemCantina,
  content,
} from "../assets/styles/AboutTwo.styles";

import ImgEscolaDePresente from "../assets/images/LP-kingdom/escola-sem-cantina.png";
import CircleSvg from "../assets/images/LP-kingdom/circle.svg";

const AboutTwo = () => {
  const [setCounter] = useState({
    startCounter: false,
  });
  const isMobile = useMediaQuery({
    query: '(max-width: 850px)'
  })

  return (
    <section css={EscolaSemCantina}>
      {isMobile && 
      <Col
      className="img-container px-0"
      data-aos="fade-right"
      lg={6}
      md={12}
      sm={12}
    >
      <img src={ImgEscolaDePresente} alt=" " />
    </Col>}
      <Container fluid>
        <Row className="title-content">
        {!isMobile &&
          <Col
          className="img-container px-0"
          data-aos="fade-right"
          lg={6}
          md={12}
          sm={12}
        >
          <img src={ImgEscolaDePresente} alt=" " />
        </Col>}
          <Col css={content} lg={6} md={12} sm={12}>
            <div>
              <h1 style={{ maxWidth: 450 }}>Uma escola que não tem cantina. Tem alimentação saudável, de qualidade e deliciosa</h1>
              <p style={{ maxWidth: 450, marginBottom: 24 }}>Na Kingdom, a alimentação atende a todos, inclusive em suas restrições e adaptações.</p>
              <ul>
                <li><img src={CircleSvg} /> Alimentação livre de conservantes, corantes, e industrializados</li>
                <li><img src={CircleSvg} /> Cooking class</li>
                <li><img src={CircleSvg} /> Aulas de gastronomia e nutrição</li>
                <li><img src={CircleSvg} /> Alimentos frescos e saudáveis</li>
              </ul>
            </div>
          </Col>        
        </Row>
      </Container>
    </section>
  );
};

export default AboutTwo;
