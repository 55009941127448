/** @jsx jsx */
import { css } from "@emotion/react";

export const escolaAutoral = css`
  height: auto;
  padding: 80px 0;
  background: #F5F5F5;
  @media (max-width: 500px){
    height: auto;
    margin-bottom: 0px;
   }
  .imagemEscolaComposta {
    display: flex;
    justify-content: center;
    @media (max-width: 500px){
      width: 100%;
      height: 405.259px;
      object-fit: cover;
      margin-inline-end: 34px;
      padding-right: 0px;
    }
  }
  h2 {
    flex-shrink: 0;
    color: var(--Color-Black-Kingdom, #3F3F3F);
    font-family: Montserrat;
    font-size: 36px;
    font-style: normal;
    font-weight: 600;
    line-height: 100%;  
    letter-spacing: -1.8px;
    width: 100%;
    max-width: 496px;
    margin-bottom: 40px;
    @media (max-width: 500px){
      font-size: 30px;
    }
  }
  p {
    flex-shrink: 0;
    flex-shrink: 0;
    color: var(--Color-Gray-Kingdom--1, #7E7E7E);
    font-family: Montserrat;
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: 150%;  
    width: 100%;
    max-width: 540px;
  }
  p:not(:last-of-type) {
    margin-bottom: 32px;
  }

  .btn-section {
    margin-top: 56px;
    font-family: "montserrat", sans-serif;
    align-items: center;
    @media (max-width: 500px){
      margin: 64px 0;
    }
  a {
    border-radius: 56px;
    padding: 11.5px 24px;
    text-align: center;
    font-size: 16px;
    font-style: normal;
    font-weight: 600;
    line-height: 120%;
    text-transform: none;
    letter-spacing: none;
  }
  a:first-of-type {
    margin-right: 16px;
    background: #5315FF;
    padding: 11.5px 24px;
    color: #F5F5F5;
    :hover {
      text-decoration: none;
      color: #F5F5F5;
    }
    :active {
      background: #000721;
      color: #FFFFFF
    }
  }
  a:nth-of-type(2) {
    margin-right: 16px;
    background: blue;
    padding: 11.5px 24px;
    color: #FFFFFF;
    :hover {
      text-decoration: none;
      color: #FFFFFF
    }
    :active {
      background: #062871;
      color: #FFFFFF
    }
  }
  
  @media (max-width: 800px) {
      display: flex;
      justify-content: center;
  } 
  }
  
`
export const ctaBemEstar = css`
  padding: 87px 0;
  background: #5315FF;
  h2 {
    flex-shrink: 0;
    color: #FFFFFF;
    text-align: center;
    font-family: Montserrat;
    font-size: 56px;
    font-style: normal;
    font-weight: 600;
    line-height: 100%; /* 56px */
    letter-spacing: -2.8px;
    width: 100%;
    max-width: 730px;
    margin: auto;
    @media (max-width: 500px){
      font-size: 36px;
    }
  }
  @media (max-width: 500px){
      padding: 48px;
    }
  
`

export const salaLAB = css`
  height: auto;
  padding-top: 0px;
  padding-bottom: 80px;
  background: #F5F5F5;
  @media (max-width: 500px){
    height: auto;
    margin-bottom: 0px;
   }
  .imagemEscolaComposta {
    display: flex;
    justify-content: center;
    @media (max-width: 500px){
      width: 100%;
      height: 405.259px;
      object-fit: cover;
      margin-inline-end: 34px;
      padding-right: 0px;
    }
  }
  h2 {
    flex-shrink: 0;
    color: #3F3F3F;
    font-family: Montserrat;
    font-size: 36px;
    font-style: normal;
    font-weight: 600;
    line-height: 100%;  
    letter-spacing: -1.8px;
    width: 100%;
    max-width: 496px;
    margin-bottom: 40px;
    @media (max-width: 500px){
      font-size: 30px;
      margin-top: 32px;
    }
  }
  p {
    flex-shrink: 0;
    flex-shrink: 0;
    color: #7E7E7E;
    font-family: Montserrat;
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: 150%;  
    width: 100%;
    max-width: 540px;
    margin-bottom: 0px;
  }
  .content-box {
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
  }
`