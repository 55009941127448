/** @jsx jsx */
import { jsx } from '@emotion/react'
import { Fragment } from 'react'
import { Parallax } from 'react-parallax';
import BgSection from "../assets/images/LP-kingdom/header-ensino-fundamental-i.png";

import { HeaderSection } from '../assets/styles/AboutTwo.styles'

const HeaderTitleContent = () => {

  return (
    <Fragment>
      <Parallax bgImage={BgSection} bgImageAlt="Ensino Fundamental I" strength={100}>
        <section css={HeaderSection}>
          <div className="div-align">
            <h1>Ensino Fundamental II</h1>
          </div>
          <div className="parallax-element"></div>
        </section>
      </Parallax>
    </Fragment>
  )
}

export default HeaderTitleContent;
