/** @jsx jsx */
import React from "react";
import { jsx } from '@emotion/react'
import { Fragment } from 'react'
import { Parallax } from 'react-parallax';
import { Link } from "gatsby";
import BgSection from "../assets/images/LP-kingdom/atividade-fisica.png";

import {ctaAtividadeFisica } from '../assets/styles/AboutTwo.styles'

const CTAMaisTempoParaOBemEF = () => {

  return (
    <Fragment>
      <Parallax bgImage={BgSection} bgImageAlt="the cat" strength={0}>
        <section css={ctaAtividadeFisica}>
          <div className="div-align">
            <h2>A ativação física contribui para a organização mental da criança</h2>
            <p>Aulas de educação física diárias ministradas em inglês</p>
          </div>
          <div className="parallax-element"></div>
        </section>
      </Parallax>
    </Fragment>
  )
}

export default CTAMaisTempoParaOBemEF
